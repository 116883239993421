import { ref, reactive } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import router from '@/router'
// import store from '@/store'
import useAlertNotification from '@/services/notification/useAlert'
import useAuth from '../auth/authService'
// import axios from 'axios'
export default function useSignatures() {
  const { errorToast } = useAlertNotification()
  const { TRUSTEDX_AUTHORIZATION_URI } = useAuth()

  const loader = ref(false)
  const signatureProcess = ref(false)
  const processId = ref('')
  const pdfFileUrl = ref('')
  const signatureSuccess = ref(false)

  const errors = ref('')
  // Delete Process Signature

  const deleteProcess = async () => {
    const user = await axiosClient.get('/user')

    const data = reactive({
      access_token: user.data.pki_token,
      signerProcessId: processId.value,
    })
    try {
      await axiosClient.post('/delete-process', { ...data })
    } catch (error) {
      // Vérifier si le la pki_token est expirée et rediriger vers une nouvelle authentification
      console.log(error)
    }
  }

  // Download Document PDF
  const downloadDocument = async (documentId, status, requestCertificateId) => {
    const user = await axiosClient.get('/user')

    const data = reactive({
      access_token: user.data.pki_token,
      documentId,
      requestCertificateId,
      status,
    })
    try {
      const response = await axiosClient.post('/download-document', { ...data })
      pdfFileUrl.value = response.data
      await deleteProcess()
      // window.location.href = pdfFileUrl.value
      window.open(
        `${pdfFileUrl.value}`,
        '_blank', // <- This is what makes it open in a new window.
      )
      router.push({ name: 'espace-signataire.detail-demande', params: { id: requestCertificateId } })
    } catch (error) {
      // Vérifier si le la pki_token est expirée et rediriger vers une nouvelle authentification

      console.log(error)
    }
  }

  // Création du processus de signature

  const createDocumentProcess = async () => {
    signatureProcess.value = true
    try {
      const user = await axiosClient.get('/user')
      const data = reactive({
        access_token: user.data.pki_token,
        request_certificate_id: router.currentRoute.params.id,
      })
      const response = await axiosClient.post('/create-document-process', { ...data })
      if (response.data.message === 'Demande de certificat déjà traitée, vous ne pouvez plus signer !') {
        errorToast(' Oups Erreur', response.data.message)
      } else {
        if (response.data === '') {
          window.location.href = TRUSTEDX_AUTHORIZATION_URI.value
        }
        signatureSuccess.value = true
        signatureProcess.value = true

        window.location.href = response.data.tasks.pending[0].url
      }
    } catch (error) {
      // eslint-disable-next-line no-prototype-builtins
      if (error.hasOwnProperty('response') && error.response.status === 401) {
        window.location.href = TRUSTEDX_AUTHORIZATION_URI.value
      }
      signatureSuccess.value = false
      signatureProcess.value = false
      // Vérifier si le la pki_token est expirée et rediriger vers une nouvelle authentification

      console.log(error)
    }
  }

  // Exécution du processus de signature

  const getDocumentInformation = async (signerProcessId, status, requestCertificateId) => {
    const user = await axiosClient.get('/user')

    const data = reactive({
      access_token: user.data.pki_token,
      signerProcessId,
      status,
    })
    try {
      processId.value = signerProcessId
      const response = await axiosClient.post('/obtained-document-info', { ...data })

      await downloadDocument(response.data.documents[0].id, response.data.documents[0].result.status, requestCertificateId)
      // Vérifier le status et rediriger sur url_pending
    } catch (error) {
      // Vérifier si le la pki_token est expirée et rediriger vers une nouvelle authentification

      console.log(error)
    }
  }

  return {
    errors,
    loader,
    createDocumentProcess,
    getDocumentInformation,
    deleteProcess,
    TRUSTEDX_AUTHORIZATION_URI,
  }
}
