import { ref } from '@vue/composition-api'
import router from '@/router'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useRequestCertificates() {
  const { errorToast, successToast } = useAlertNotification()
  const requestCertificate = ref([])
  const requestCertificateEnCours = ref([])
  const requestCertificateRejete = ref([])
  const requestCertificateSoumis = ref([])
  const requestCertificateTraite = ref([])
  const requestCertificateEnCoursByMairie = ref([])
  const requestCertificateRejeteByMairie = ref([])
  const requestCertificateSoumiseByMairie = ref([])
  const requestCertificateTraiteByMairie = ref([])
  const requestCertificateSigned = ref([])
  const requestCertificateAwaitingSignature = ref([])
  const loader = ref(false)
  const requestCertificateProcess = ref(false)
  const pdfCertificate = ref('')
  const requestCertificateSuccess = ref(false)
  const requestCertificates = ref([])
  const errors = ref('')

  const listRequestCertificatesByMairieByStatus = async (mairieId, status) => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-all-list-request-certificates-bymairie-bystatus/${mairieId}/${status}`)
      if (response.data.success === true) {
        loader.value = false
        requestCertificates.value = response.data.data
        if (status === 'En cours') requestCertificateEnCoursByMairie.value = response.data.data
        else if (status === 'Rejeté') requestCertificateRejeteByMairie.value = response.data.data
        else if (status === 'Traité') requestCertificateTraiteByMairie.value = response.data.data
        else if (status === 'Soumis') requestCertificateSoumiseByMairie.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const listRequestCertificatesPendingByMairie = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-list-request-certificates-pending-bymairie')
      if (response.data.success === true) {
        loader.value = false
        requestCertificates.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const listRequestCertificatesByMairieAwaitingSignature = async isSigned => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-all-list-request-certificates-awaiting-signature-bymairie/${isSigned}`)
      if (response.data.success === true) {
        loader.value = false
        requestCertificates.value = response.data.data
        if (isSigned === 1) requestCertificateSigned.value = response.data.data
        else requestCertificateAwaitingSignature.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const findRequestCertificateById = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-request-certificate-by-id/${router.currentRoute.params.id}`)
      if (response.data.success === true) {
        loader.value = false
        requestCertificate.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const generatePdf = async () => {
    try {
      loader.value = true
      const response = await axiosClient.patch(`/generate-pdf/${router.currentRoute.params.id}`)
      if (response.data.success === true) {
        loader.value = false
        pdfCertificate.value = response.data.data.file_url
      }
    } catch (error) {
      loader.value = false
    }
  }
  const listRequestCertificatesByUserByStatus = async status => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-all-list-request-certificates-byuser-bystatus/${status}`)
      if (response.data.success === true) {
        loader.value = false
        requestCertificates.value = response.data.data
        if (status === 'En cours') requestCertificateEnCours.value = response.data.data
        else if (status === 'Rejeté') requestCertificateRejete.value = response.data.data
        else if (status === 'Traité') requestCertificateTraite.value = response.data.data
        else if (status === 'Soumis') requestCertificateSoumis.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  // Soumettre une demande
  const storeRequestCertificate = async data => {
    try {
      errors.value = ''
      requestCertificateProcess.value = true
      const response = await axiosClient.post('/create-request-certificate', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        requestCertificateSuccess.value = true
        requestCertificateProcess.value = false
        router.push({ name: 'espace-utilisateur.list-demandes-soumises' })
      }
    } catch (error) {
      requestCertificateProcess.value = false
      requestCertificateSuccess.value = false
      console.log(error)
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorToast('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    requestCertificate,
    storeRequestCertificate,
    requestCertificateProcess,
    listRequestCertificatesByMairieByStatus,
    listRequestCertificatesByUserByStatus,
    listRequestCertificatesPendingByMairie,
    loader,
    requestCertificateSigned,
    requestCertificateAwaitingSignature,
    listRequestCertificatesByMairieAwaitingSignature,
    requestCertificateSuccess,
    requestCertificates,
    requestCertificateEnCours,
    requestCertificateRejete,
    generatePdf,
    pdfCertificate,
    requestCertificateSoumis,
    requestCertificateTraite,
    findRequestCertificateById,
    errors,
    requestCertificateEnCoursByMairie,
    requestCertificateRejeteByMairie,
    requestCertificateTraiteByMairie,
    requestCertificateSoumiseByMairie,
  }
}
