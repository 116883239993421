<template>
  <b-row>
    <b-col md="12">
      <b-row>
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mb-1 ml-1"
            onclick="history.back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" />
            Retour
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-row>
            <b-col md="12">
              <b-card no-body>
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      active
                    >
                      <span>Informations sur la demande</span>

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Document demandé</span>
                      {{ requestCertificate.type_certificat.name }}

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Etat de la demande</span>
                      <b-badge
                        pill
                        :variant="`${resolveRequestCertificateStatusVariant(requestCertificate.status)}`"
                      >
                        {{ requestCertificate.status }}
                      </b-badge>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Date de la demande</span>
                      {{ requestCertificate.created_at }}

                    </b-list-group-item>

                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12">
              <b-card no-body>
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      active
                    >
                      <span>Informations de l'impétrant</span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Nom de l'impétrant</span>
                      {{ requestCertificate.demandeur.name }}

                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>NPI</span>
                      {{ requestCertificate.demandeur.npi }}

                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Numéro de téléphone</span>
                      {{ requestCertificate.demandeur.phonenumber }}

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Adresse e-mail</span>
                      {{ requestCertificate.demandeur.email }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>

          </b-row>
        </b-col>
        <b-col md="7">
          <b-row>
            <b-col md="12">
              <b-card title="Suivi du processus de validation">
                <ul class="progress_bar pb-5">
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${requestCertificate.created_at}`"
                    :class="requestCertificate.status === 'Soumis' || requestCertificate.status === 'En cours' || requestCertificate.status === 'Traité' || requestCertificate.status === 'Rejeté' ? 'active' : ''"
                  >
                    Soumise
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${requestCertificate.date_encours}`"
                    :class="requestCertificate.status === 'En cours' || requestCertificate.status === 'Traité' || requestCertificate.status === 'Rejeté' ? 'active' : ''"
                  >
                    En cours
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${processCertificateVerification.date_validee} | Agent Vérificateur: ${processCertificateVerification.operateur.name}`"
                    :class="processCertificateVerification.status === 'Validé' || requestCertificate.status === 'Traité' ? 'active' : ''"
                  >
                    Vérification
                  </li>
                  <li
                    v-b-tooltip.hover.bottom="`Date : ${processCertificateSignature.date_validee} | Signataire : ${processCertificateSignature.operateur.name}`"
                    :class="processCertificateSignature.status === 'Validé' || requestCertificate.status === 'Traité' ? 'active' : ''"
                  >
                    Signature
                  </li>
                </ul>
                <b-card-body />
              </b-card>
            </b-col>
            <b-col md="12">
              <b-card title="Pièces fournies">
                <b-card-body>
                  <b-table
                    striped
                    hover
                    size="sm"
                    responsive
                    :busy="loader"
                    class="position-relative"
                    :items="items"
                    :fields="fields"
                  >
                    <template #table-busy>
                      <div class="d-flex justify-content-center mb-3">
                        <b-spinner
                          style="width: 2rem; height: 2rem;"
                          variant="primary"
                          label="Large Spinner"
                          type="grow"
                          class="mt-2"
                        />
                        <span class="sr-only">Chargement en cours...</span>
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        pill
                        :variant="`light-${resolvePieceStatusVariant(data.item.status)}`"
                      >
                        {{ data.item.status }}
                      </b-badge>
                    </template>
                    <template #cell(actions)="data">
                      <div class="text-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-tooltip.hover.bottom="'Voir la pièce'"
                          v-b-modal.piece-display
                          variant="dark"
                          size="sm"
                          class="btn-icon rounded-circle ml-1"
                          @click.prevent="getPdfFile(data.item)"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-button>

                      </div>
                    </template>

                  </b-table>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12">
              <b-card title="Informations certificat">
                <b-card-body>
                  <b-table
                    striped
                    hover
                    size="sm"
                    responsive
                    :busy="loader"
                    class="position-relative"
                    :items="itemsCertificat"
                  >
                    <template #table-busy>
                      <div class="d-flex justify-content-center mb-3">
                        <b-spinner
                          style="width: 2rem; height: 2rem;"
                          variant="primary"
                          label="Large Spinner"
                          type="grow"
                          class="mt-2"
                        />
                        <span class="sr-only">Chargement en cours...</span>
                      </div>
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        pill
                        :variant="`light-${resolvePieceStatusVariant(data.item.status)}`"
                      >
                        {{ data.item.status }}
                      </b-badge>
                    </template>
                    <template #cell(actions)="data">
                      <div class="text-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-tooltip.hover.bottom="'Voir la pièce'"
                          v-b-modal.modal-display
                          variant="dark"
                          size="sm"
                          class="btn-icon rounded-circle ml-1"
                          @click.prevent="getPdfFile(data.item)"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-button>
                        <b-button
                          v-if="data.item.status === 'Rejeté'"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-tooltip.hover.bottom="'Modifier la pièce'"
                          v-b-modal.update-piece
                          variant="primary"
                          size="sm"
                          class="btn-icon rounded-circle ml-1"
                          @click.prevent="getPdfFile(data.item)"
                        >
                          <feather-icon icon="EditIcon" />
                        </b-button>

                      </div>
                    </template>

                  </b-table>
                  <b-row>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-tooltip.hover.bottom="'Signer'"
                        variant="success"
                        size="sm"
                        class=" float-right mb-1 ml-1"
                        :disabled="requestCertificate.is_signed===true"
                        @click="createDocumentProcess"
                      >
                        Signer le certificat
                        <feather-icon icon="AwardIcon" />
                        <b-spinner
                          v-if="signatureProcess"
                          small
                          label="Loading..."
                          variant="light"
                          class="ml-1"
                        />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-tooltip.hover.bottom="'Prévisualiser le document signé'"
                        v-b-modal.modal-display
                        variant="dark"
                        size="sm"
                        class=" float-right mb-1"
                        @click.prevent="requestCertificate.is_signed ? getCertificatePdf(requestCertificate.signed_file_url) : generatePdf()"
                      >
                        Prévisualiser
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>

            </b-col>
          </b-row>
        </b-col>

      </b-row>
    </b-col>
    <!-- modal -->
    <b-modal
      id="modal-display"
      ref="my-modal"
      size="xl"
      button-size="sm"
      title="Certificat"
      ok-only
      ok-title="Fermer"
    >
      <b-card style="height: 500px;">
        <iframe
          style="height: 500px; width: 100%;"
          :src="requestCertificate.is_signed ? PDFURL : pdfCertificate"
        />
      </b-card>
    </b-modal>
    <!-- modal -->
    <b-modal
      id="piece-display"
      ref="my-modal"
      size="xl"
      button-size="sm"
      title="Pièce"
      ok-only
      ok-title="Fermer"
    >
      <b-card style="height: 500px;">
        <iframe
          style="height: 500px; width: 100%;"
          :src="PDFURL"
        />
      </b-card>
    </b-modal>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  onMounted, reactive, ref,
} from '@vue/composition-api'
import {
  BRow, BCol, BCard, BBadge, BTable, BButton, BCardBody, BSpinner, VBTooltip, BListGroup, BListGroupItem, BModal,
} from 'bootstrap-vue'

import { required } from '@validations'

// eslint-disable-next-line import/no-cycle
import useRequestCertificates from '@/services/requestCertificate/requestCertificate'
// eslint-disable-next-line import/no-cycle
import useProcessCertificates from '@/services/agent/processCertificateService'

// eslint-disable-next-line import/no-cycle
import useSignatures from '@/services/signature/signatureService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BCardBody,
    BBadge,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BModal,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      findProcessCertificateVerification, findProcessCertificateSignature, processCertificateVerification, processCertificateSignature,
    } = useProcessCertificates()
    const {
      createDocumentProcess, signatureProcess,
    } = useSignatures()
    const {
      requestCertificate, loader, findRequestCertificateById, generatePdf, pdfCertificate,
    } = useRequestCertificates()
    const fields = [
      { key: 'document_type.label', label: 'Pièce', sortable: true },
      { key: 'status', label: 'Etat', sortable: true },
      { key: 'numero_piece', label: 'N°Pièce', sortable: true },
      { key: 'commentaire', label: 'Commentaire', sortable: true },
      { key: 'actions', label: 'Actions', sortable: true },
    ]
    const formPiece = reactive({
      numero_piece: '',
      id: null,
      document_type_id: '',
      request_certificate_id: '',
      filename: null,
    })
    const items = ref([])
    const itemsCertificat = ref([])

    onMounted(async () => {
      await findRequestCertificateById()
      await findProcessCertificateVerification()
      await findProcessCertificateSignature()
      items.value = requestCertificate.value.attachements
      itemsCertificat.value = [
        {
          numero_demande: requestCertificate.value.id, type_certificat: requestCertificate.value.type_certificat.name, duree_traitement: `${requestCertificate.value.duree_traitement} jour(s)`,
        },
      ]

      console.clear()
    })
    const resolvePieceStatusVariant = status => {
      if (status === 'Envoyé') return 'warning'
      if (status === 'Validé') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }
    const resolveRequestCertificateStatusVariant = status => {
      if (status === 'Soumis') return 'primary'
      if (status === 'En cours') return 'warning'
      if (status === 'Traité') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }
    const PDFURL = ref('')
    const getPdfFile = item => {
      PDFURL.value = ''
      PDFURL.value = item.pdf_url
    }
    const getCertificatePdf = pdf => {
      PDFURL.value = ''
      PDFURL.value = pdf
    }

    const formatter = value => value.toUpperCase()
    return {
      getCertificatePdf,
      itemsCertificat,
      requestCertificate,
      processCertificateVerification,
      processCertificateSignature,
      formatter,
      loader,
      createDocumentProcess,
      signatureProcess,
      required,
      PDFURL,
      generatePdf,
      getPdfFile,
      resolveRequestCertificateStatusVariant,
      resolvePieceStatusVariant,
      fields,
      items,
      pdfCertificate,
      formPiece,
    }
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.progress_bar {

  display: flex;
  counter-reset: circle 0;
}

.progress_bar li {
  list-style: none;
  margin-right: 57px;
  counter-increment: circle 1;
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.progress_bar li::before {
  content: counter(circle);
  position: absolute;
  top: 30px;
  height: 40px;
  width: 40px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border-radius: 50%;
  z-index: 10;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 120px;
  background-color: #f6f6f6;
  top: 48px;
  right: 37px;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li:nth-child(1)::after {
  display: none;
}

.progress_bar li.active::before {
  background-color: #005aa4 !important;
  color: #f6f6f6 !important;
}

.progress_bar li.active::after {
  background-color: #005aa4 !important;
}
</style>
