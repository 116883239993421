import axiosClient from '@/helpers/axios'
import { reactive, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/services/super-admin/userService'
import router from '@/router'
import store from '@/store'
import useAlertNotification from '@/services/notification/useAlert'

export default function useAuth() {
  const { successToast } = useAlertNotification()
  const { createUser, user } = useUsers()
  // Variables de configuration de la PKI
  const CLIENT_ID = 'appdde'
  const TRUSTEDX_SCOPE = 'urn:gob:basic:profile urn:safelayer:eidas:sign:process:document'
  const credentials = ref('appdde:Test@2022')
  const redirectUri = ref('https://e-certificat.mameribj.org/waiting-page')
  const trustedx = ref(`https://${credentials.value}@test-tx-pki.gouv.bj/trustedx-authserver/oauth/main-as`)

  // Demande de code (Authorization Request)

  const TRUSTEDX_AUTHORIZATION_URI = ref(`${trustedx.value}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${redirectUri.value}&scope=${TRUSTEDX_SCOPE}`)

  // Rediriger l'utilisateur sur son dashboard selon son rôle

  const redirectUser = async role => {
    switch (role) {
      case 'SUPER-ADMINISTRATEUR':
        router.push({ name: 'espace-super-admin.dashboard' })
        break
      case 'ADMINISTRATEUR':
        router.push({ name: 'espace-admin.dashboard' })

        break
      case 'SIGNATAIRE':
        router.push({ name: 'espace-signataire.dashboard' })
        break
      case 'AGENT VERIFICATEUR':
        router.push({ name: 'espace-agent.dashboard' })
        break
      case 'UTILISATEUR':
        router.push({ name: 'espace-utilisateur.dashboard' })
        break
      default:
        break
    }
  }
  const userAuth = async token => {
    const data = reactive({
      access_token: token,
    })
    try {
      const response = await axiosClient.post('/user-info-access', { ...data })
      response.data.data.pki_token = token
      await createUser(response.data.data)
      await redirectUser(user.value.role)
    } catch (error) {
      console.log(error)
    }
  }

  // Demande de token (Access token)
  const accessToken = async () => {
    // eslint-disable-next-line no-prototype-builtins
    const { code } = router.currentRoute.query
    const data = reactive({
      grant_type: 'authorization_code',
      code,
      redirect_uri: redirectUri.value,
    })
    try {
      const response = await axiosClient.post('/obtain-token', { ...data })
      if (response.data.data.access_token) {
        await userAuth(response.data.data.access_token)
      } else {
        window.location.href = TRUSTEDX_AUTHORIZATION_URI.value
      }
    } catch (error) { console.log(error) }
  }

  const logout = async () => {
    try {
      const response = await axiosClient.post('/logout')
      successToast(response.data.message)
      store.state.authStore.token = null
      store.state.authStore.authenticated = false
      store.state.authStore.user = {}
      localStorage.removeItem('vuex')
      localStorage.clear()
      await router.push({ name: 'login' })
    } catch (error) {
      // eslint-disable-next-line no-prototype-builtins
      if (error.hasOwnProperty('response')) {
        successToast('Logged successful!')
        store.state.authStore.token = null
        store.state.authStore.authenticated = false
        store.state.authStore.user = {}
        localStorage.removeItem('vuex')
        localStorage.clear()

        await router.push({ name: 'login' })
      }
    }
  }

  return {
    TRUSTEDX_AUTHORIZATION_URI,
    redirectUser,
    accessToken,
    logout,
  }
}
