import { ref } from '@vue/composition-api'
import router from '@/router'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useProcessCertificates() {
  const { errorToast, successToast } = useAlertNotification()
  const processCertificateVerification = ref([])
  const processCertificateSignature = ref([])
  const loader = ref(false)
  const processCertificateProcess = ref(false)
  const processCertificateSuccess = ref(false)
  const processCertificates = ref([])
  const errors = ref('')

  const findProcessCertificateVerification = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-process-certificate-verification/${router.currentRoute.params.id}`)
      if (response.data.success === true) {
        loader.value = false
        processCertificateVerification.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const findProcessCertificateSignature = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-process-certificate-signature/${router.currentRoute.params.id}`)
      if (response.data.success === true) {
        loader.value = false
        processCertificateSignature.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const validationProcessCertificate = async data => {
    try {
      errors.value = ''
      processCertificateProcess.value = true
      const response = await axiosClient.patch(`/change-status-process-certificate/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Changement de status', response.data.message)
        processCertificateSuccess.value = true
        processCertificateProcess.value = false
      }
    } catch (error) {
      processCertificateProcess.value = false
      processCertificateSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      if (error.response.status === 401) errorToast('Oups! Erreur', error.response.data.message)
      else {
        errors.value = error.response.data.errors
        const responseErrors = error.response.data.errors
        Object.keys(responseErrors).forEach(key => {
          errorToast('Oups! Erreur', responseErrors[key][0])
        })
      }
    }
  }
  return {
    findProcessCertificateVerification,
    findProcessCertificateSignature,
    validationProcessCertificate,
    processCertificateVerification,
    processCertificateSignature,
    processCertificateProcess,
    loader,
    processCertificateSuccess,
    processCertificates,
    errors,
  }
}
